import L from 'leaflet';
import MapboxConfig from '@/config/MapboxConfig';
import mapbox from '@/config/Mapbox';
import DashboardService from '@/services/DashboardService';
import mapFeaturesStylesMixins from '@/mixins/mapFeaturesStylesMixins';
import mapConfigMixins from '@/mixins/mapConfigMixins';
import scaleMixins from '@/mixins/leaflet/scaleMixins';
import '@/plugins/leaflet-icon-glyph-v2';

const mapboxConfig = new MapboxConfig(mapbox.access_token, mapbox.style);

export default {
  mixins: [mapFeaturesStylesMixins, mapConfigMixins, scaleMixins],

  props: {
    relatorio: {
      type: Object,
      default: () => {}
    },
    linhasRelatorio: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      dados: {
        arat: null
      },
      map: null
    };
  },

  watch: {
    linhasRelatorio() {
      this.desenhaMapa();
    }
  },

  methods: {
    getDadosAreaConcessao() {
      return DashboardService.getDados('AreaConcessao');
    },

    desenhaMapa() {
      this.configuraMapa(3);
      this.setView();
      this.flyTo();
      this.plotaFeicoesMapa();
      this.criaLegenda();
    },

    configuraMapa(zoom) {
      if (this.map) {
        this.map.remove();
      }

      this.map = L.map(this.mapaId, {
        fullscreenControl: true,
        layers: [mapboxConfig.getDefaultLayer()],
        zoom
      });

      L.control.layers(mapboxConfig.getBaseLayers()).addTo(this.map);

      this.createScaleControl(L).addTo(this.map);
    },

    setView() {
      this.map.setView([-2.5606242, -44.4016494]);
    },

    async flyTo() {
      if (!this.dados.arat) {
        const [x, y] = this.$store.getters.getCompanyCapitalCoordinates;

        const response = await this.getDadosAreaConcessao();
        this.dados.arat =
          response && response.data && response.data.arat
            ? response.data.arat
            : {
                centroid_x: x,
                centroid_y: y,
                zoom: 7
              };
      }

      const { centroid_x: x, centroid_y: y, zoom } = this.dados.arat;

      this.map.flyTo(
        {
          lon: x,
          lat: y
        },
        zoom ? zoom : 7
      );
    },

    plotaFeicoesMapa() {},

    criaLegenda() {},

    msgPopup(linha) {
      let msg = '';

      Object.keys(linha).forEach((campo) => {
        const dado = linha[campo];
        if (campo === 'geom' || dado === '' || dado === null) return;
        msg += `<b>${campo}:</b> ${linha[campo]}<br>`;
      });

      return msg;
    }
  }
};
